import React, { useContext, useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { Header } from './components/Header'
import { BarcodeSearch } from './components/pages/search/BarcodeSearch'
import { SerialSearch } from './components/pages/search/SerialSearch'
import { VoucherTimeline } from './components/pages/voucherTimeline/VoucherTimeline'
import { StatisticsContainer } from './components/pages/organizationStatistics/StatisticsContainer'
import { getItemFromWebStorage, loggedInUserHasRole } from './lib'
import { SearchHistoryContext } from './contexts'
import { UsersContainer } from './components/pages/users/UsersContainer'

export const App = () => {
  const { setSearchHistory } = useContext(SearchHistoryContext)

  useEffect(() => {
    getItemFromWebStorage('SEARCH_HISTORY').then(history => {
      if (history) {
        setSearchHistory(history)
      }
    })
  }, [setSearchHistory])

  return (
    <React.Fragment>
      <Header />

      {loggedInUserHasRole('VOUCHER_CONTROL_ADMIN') ? (
        <div className="mx-auto max-w-screen-2xl">
          <Switch>
            <Route path="/transactions/:barcode/:organizationId" component={VoucherTimeline} />
            <Route path="/search/barcode" component={BarcodeSearch} />

            {loggedInUserHasRole('VOUCHER_CONTROL_ADMIN_MACHINE_SEARCH') && (
              <Route path="/search/serial" component={SerialSearch} />
            )}

            {loggedInUserHasRole('VOUCHER_CONTROL_ADMIN_STATISTICS_SEARCH') && (
              <Route path="/statistics" component={StatisticsContainer} />
            )}

            {loggedInUserHasRole('VOUCHER_CONTROL_ADMIN_MANAGE_SOAP_CLIENTS') && (
              <Route path="/users" component={UsersContainer} />
            )}

            <Route render={() => <Redirect to="/search/barcode" />} />
          </Switch>
        </div>
      ) : (
        <div className="alert alert-warning centerAbsolute">You're not authorized to use this application</div>
      )}
    </React.Fragment>
  )
}
