import styled from 'styled-components'

type Props = {
  small?: boolean
  isFirstRow: boolean
  isLastRow: boolean
}

const Circle = styled.div`
  position: relative;
  min-width: ${(props: Props) => (props.small ? '15px' : '30px')};
  min-height: ${(props: Props) => (props.small ? '15px' : '30px')};
  margin: ${(props: Props) => (props.small ? '7.5px' : 0)};
  border-radius: 100px;
  background-color: var(--colors-blue);
  z-index: 1;

  :after {
    content: '';
    display: ${(props: Props) => (props.isFirstRow ? 'none' : 'initial')};
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    height: 70px;
    width: 1px;
    background-color: var(--colors-blue);
    z-index: 0;
  }

  :before {
    content: '';
    display: ${(props: Props) => (props.isLastRow ? 'none' : 'initial')};
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    height: 70px;
    width: 1px;
    background-color: var(--colors-blue);
    z-index: 0;
  }
`

export default Circle
