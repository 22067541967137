import React, { useEffect, useMemo } from 'react'
import { useState } from 'react'
import { UserDialog } from './UserDialog'
import { OrganizationType, RequestStatusType, SoapClientType } from './types'
import { AddNewUserDialog } from './AddNewUserDialog'
import { API_HOST, authenticatedFetchData, logError } from '../../../lib'
import { SortableTh } from '../../common/SortableTh'
import { sortKeyAscending, sortKeyDescending } from '../../../lib/sort'

export const UsersContainer = () => {
  const [soapClients, setSoapClients] = useState<SoapClientType[]>()
  const [fetchSoapClientsRequestStatus, setFetchSoapClientsRequestStatus] = useState<RequestStatusType>('pending')
  const [organizations, setOrganizations] = useState<OrganizationType[]>()
  const [fetchOrganizationsRequestStatus, setFetchOrganizationsRequestStatus] = useState<RequestStatusType>('pending')
  const isLoading = fetchOrganizationsRequestStatus === 'pending' || fetchSoapClientsRequestStatus === 'pending'
  const [showAddNewUserDialog, setShowAddNewUserDialog] = useState(false)
  const [selectedUser, setSelectedUser] = useState<SoapClientType>()
  const [sortOrder, setSortOrder] = useState<SortOrderType>('descending')
  const [sortKey, setSortKey] = useState<string>('userName')

  useEffect(() => {
    const fetchSoapClients = authenticatedFetchData(`${API_HOST}/v1.0/soap-clients`)

    fetchSoapClients.run().then(
      (soapClients: SoapClientType[]) => {
        setSoapClients(soapClients)
        setFetchSoapClientsRequestStatus('success')
      },
      (error: Error) => {
        if (error.name !== 'AbortError') {
          setFetchSoapClientsRequestStatus('failure')
          logError(new Error('Failed to fetch organizations.'), error)
        }
      }
    )

    const fetchSoapOrganizations = authenticatedFetchData(`${API_HOST}/v1.0/soap-clients/organization`)

    fetchSoapOrganizations.run().then(
      (organizations: OrganizationType[]) => {
        setOrganizations(organizations)
        setFetchOrganizationsRequestStatus('success')
      },
      (error: Error) => {
        if (error.name !== 'AbortError') {
          setFetchOrganizationsRequestStatus('failure')
          logError(new Error('Failed to fetch organizations.'), error)
        }
      }
    )

    return () => {
      fetchSoapClients.abort()
      fetchSoapOrganizations.abort()
    }
  }, [])

  const setSort = (order: SortOrderType, newSortKey: string) => {
    setSortKey(newSortKey)
    setSortOrder(order)
  }

  const addSoapClient = (soapClient: SoapClientType) =>
    setSoapClients(soapClients => (soapClients ? [...soapClients, soapClient] : [soapClient]))

  const sortedSoapClients = useMemo(
    () => soapClients?.sort(sortOrder === 'ascending' ? sortKeyAscending(sortKey) : sortKeyDescending(sortKey)) || [],
    [soapClients, sortKey, sortOrder]
  )

  return isLoading ? (
    <div className="centerAbsolute">
      <div className="loadingSpinner" />
    </div>
  ) : fetchSoapClientsRequestStatus === 'failure' || soapClients === undefined ? (
    <h1 className="alert alert-danger">Failed to fetch soap clients</h1>
  ) : fetchOrganizationsRequestStatus === 'failure' || organizations === undefined ? (
    <h1 className="alert alert-danger">Failed to fetch organizations</h1>
  ) : (
    <div className="max-w-screen-2xl mx-auto my-md">
      {selectedUser !== undefined && <UserDialog user={selectedUser} onClose={() => setSelectedUser(undefined)} />}

      {showAddNewUserDialog && (
        <AddNewUserDialog
          organizations={organizations}
          addSoapClient={addSoapClient}
          onClose={() => setShowAddNewUserDialog(false)}
        />
      )}

      <div className="flex justify-between px-md mb-md">
        <h1 className="text-lg">Users</h1>

        <button className="btn btn-primary-dark" onClick={() => setShowAddNewUserDialog(true)}>
          New user
        </button>
      </div>

      <div className="card">
        <table className="table">
          <thead>
            <tr>
              <SortableTh fieldKey="userName" onSort={setSort} sortOrder={sortOrder} sortKey={sortKey}>
                Username
              </SortableTh>
              <SortableTh fieldKey="organizationName" onSort={setSort} sortOrder={sortOrder} sortKey={sortKey}>
                Organization name
              </SortableTh>
              <SortableTh fieldKey="organizationId" onSort={setSort} sortOrder={sortOrder} sortKey={sortKey}>
                Organization Id
              </SortableTh>
            </tr>
          </thead>
          <tbody>
            {sortedSoapClients.map(user => (
              <tr key={user.userName} className="hover:bg-grey-light" onClick={() => setSelectedUser(user)}>
                <td>{user.userName}</td>
                <td>{user.organizationName}</td>
                <td>{user.organizationId}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
