import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { ContextProvider } from './ContextProvider'
import { authentication } from './lib'
import { App } from './App'
import '@tomra/design-system/src/config/tailwind.config.css'
import 'react-datepicker/dist/react-datepicker.css'

const Index = () => (
  <React.StrictMode>
    <BrowserRouter>
      <ContextProvider>
        <App />
      </ContextProvider>
    </BrowserRouter>
  </React.StrictMode>
)

authentication.init().then(
  () => {
    ReactDOM.render(<Index />, document.getElementById('root'))
  },
  () => {
    authentication.login()
  }
)
