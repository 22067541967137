import React, { useState, ReactNode } from 'react'
import { setItemInWebStorage } from '../lib'

export const SearchHistoryContext = React.createContext<{
  searchHistory: string[]
  setSearchHistory: (history: string[]) => void
  updateSearchHistory: (url: string) => void
}>({
  searchHistory: [],
  setSearchHistory: () => {},
  updateSearchHistory: () => {}
})

type Props = {
  children: ReactNode
}

export const SearchHistoryProvider = (props: Props) => {
  const [searchHistory, setSearchHistory] = useState<string[]>([])

  function updateSearchHistory(url: string) {
    let updatedHistory

    updatedHistory = searchHistory.filter((existingUrl: string) => existingUrl !== url)
    updatedHistory.push(url)

    if (searchHistory.length > 5) {
      updatedHistory = searchHistory.slice(1, searchHistory.length)
    }

    setItemInWebStorage('SEARCH_HISTORY', updatedHistory)
    setSearchHistory(updatedHistory)
  }

  return (
    <SearchHistoryContext.Provider value={{ searchHistory, setSearchHistory, updateSearchHistory }}>
      {props.children}
    </SearchHistoryContext.Provider>
  )
}
