import * as localforage from 'localforage'
import { AuthStore, WebStorage } from '@tomra/client-side-authentication'
import { KEYCLOAK_HOST } from './environment'

const storage: WebStorage = {
  setItem: localforage.setItem,
  removeItem: localforage.removeItem,
  clear: localforage.clear,
  getItem: localforage.getItem
}

export const authentication = new AuthStore('TomraConnectUsers', 'voucher-admin-ui', KEYCLOAK_HOST, 'en', storage)
