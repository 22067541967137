export const sortKeyAscending = (sortKey: string) => (a: any, b: any) => {
  if (!a[sortKey] || !b[sortKey]) {
    return 0
  }

  return a[sortKey] < b[sortKey] ? -1 : 1
}

export const sortKeyDescending = (sortKey: string) => (a: any, b: any) => {
  if (!a[sortKey] || !b[sortKey]) {
    return 0
  }

  return a[sortKey] < b[sortKey] ? 1 : -1
}
