import React from 'react'
import styled from 'styled-components'
import Circle from './Circle'
import { DateWithUTCTooltip } from '../../../common'
import { VoucherTimelineType } from '../VoucherTimeline'

const Container = styled.div`
  > span:first-child {
    min-width: 150px;
    margin-right: var(--spacing-lg);
  }
`

type Props = {
  entry: VoucherTimelineType
  locationTimezone?: string
  isFirstRow: boolean
  isLastRow: boolean
}

export const StateRow = ({ entry, locationTimezone, isFirstRow, isLastRow }: Props) => {
  const { eventType, source, createdBy, state, reservedUntil, consumerId, rvmSerialNumber, timestamp } = entry

  const eventTypeToMessageMap = {
    CREATED: {
      RVM: `Created new ${state === 'DIGITAL' ? 'digital' : ''} voucher from RVM (serial: ${createdBy})`,
      RETAIL_ADDED: `Created from retail adding (consumer ID ${createdBy}) in state ${state}`
    },
    STATE_UPDATE: {
      NEW: `Voucher enabled for store payout (state ${state})`,
      DIGITAL: `Voucher set to digital payout (state ${state})`,
      RESERVED: `Reserved by consumer ID ${consumerId} ${reservedUntil ? 'until' : ''}`,
      CONSUMED: `Consumed by consumer ID ${consumerId} `
    },
    SOURCE_UPDATE: {
      RVM: `Retail added voucher replaced by consumer session from RVM ${rvmSerialNumber}`
    }
  }

  // @ts-ignore
  const message = eventTypeToMessageMap[eventType][source || state]

  return (
    <Container data-testid="stateRow" className="flex items-center py-md">
      <DateWithUTCTooltip date={timestamp} timeZone={locationTimezone} />
      <Circle isFirstRow={isFirstRow} isLastRow={isLastRow} />
      <span className="relative ml-lg">
        {message}
        {reservedUntil && <DateWithUTCTooltip date={reservedUntil} timeZone={locationTimezone} />}
      </span>
    </Container>
  )
}

export default StateRow
