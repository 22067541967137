import React from 'react'
import ReactDOM from 'react-dom'

type Props = {
  children: React.ReactNode
}

export const Dialog = ({ children }: Props) => {
  return ReactDOM.createPortal(
    <div className="animate-fadeIn-fast fixed top-0 right-0 bottom-0 left-0 bg-black-transparent flex items-center justify-center z-10">
      {children}
    </div>,
    // @ts-ignore
    document.getElementById('root')
  )
}
