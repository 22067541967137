import React, { useState, useEffect } from 'react'
import { StatisticsSearch } from './StatisticsSearch'
import { logError, API_HOST, authenticatedFetchData } from '../../../lib'

export const StatisticsContainer = () => {
  const [organizations, setOrganizations] = useState([])
  const [findOrganizationsStatus, setFindOrganizationsStatus] = useState('loading')

  useEffect(() => {
    const url = `${API_HOST}/v1.0/organizations`

    const f = authenticatedFetchData(url)

    f.run().then(
      (organizations: []) => {
        setFindOrganizationsStatus('')
        setOrganizations(organizations)
      },
      (error: Error) => {
        if (error.name !== 'AbortError') {
          setFindOrganizationsStatus('failed')
          logError(new Error('Failed to fetch organizations.'), error)
        }
      }
    )

    return () => {
      f.abort()
    }
  }, [])

  if (findOrganizationsStatus === 'loading') {
    return (
      <div className="centerAbsolute">
        <div className="loadingSpinner" />
      </div>
    )
  }

  if (findOrganizationsStatus === 'failed') {
    return (
      <h1 className="centerAbsolute alert alert-danger">
        Whoopsie! Failed fetch available organizations. Please try again.
      </h1>
    )
  }

  if (organizations.length === 0) {
    return <div className="centerAbsolute alert alert-warning">No available organizations to search through.</div>
  }

  return <StatisticsSearch organizations={organizations} />
}
