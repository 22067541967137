import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { VoucherList } from '../../common/VoucherList'
import { logError, API_HOST, authenticatedFetchData, getQueryParameterByName } from '../../../lib'
import { CSVLink } from 'react-csv'
import { convertVoucherDomainObjectToReadable } from '../../../lib/convertVoucherDomainObjectToReadable'
import { VoucherType } from '../voucherTimeline/VoucherTimeline'

const HEADERS = [
  { label: 'Barcode', key: 'barcode' },
  { label: 'Voucher state', key: 'voucherState' },
  { label: 'Rvm serial', key: 'rvmSerialNumber' },
  { label: 'Organization', key: 'organizationName' },
  { label: 'CS3 Start time', key: 'csTimestamp' },
  { label: 'CS3 Source', key: 'csSource' },
  { label: 'Last updated', key: 'transactionTimestamp' },
  { label: 'Last updated by', key: 'consumerId' },
  { label: 'Last updated by (sub)', key: 'consumerLocation' },
  { label: 'Refund amount', key: 'refundAmount' },
  { label: 'Is Digital', key: 'csDigital' }
]

const DATEOPTIONS: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit'
}

export const SerialResults = () => {
  const [vouchers, setVouchers] = useState<VoucherType[]>([])
  const [csvData, setCsvData] = useState<any[]>([])
  const [findVouchersStatus, setFindVouchersStatus] = useState('loading')
  const { serialNumber } = useParams<{ [key: string]: string }>()
  const fromDateInMs = getQueryParameterByName('fromDate') || ''
  const toDateInMs = getQueryParameterByName('toDate') || ''

  useEffect(() => {
    setFindVouchersStatus('loading')

    const url = `${API_HOST}/v1.0/search/serial/${serialNumber}?fromDate=${fromDateInMs}&toDate=${toDateInMs}`

    const { run, abort } = authenticatedFetchData(url)

    run().then(
      (vouchers: VoucherType[]) => {
        setFindVouchersStatus('')
        setVouchers(vouchers)
        setCsvData(vouchers.map(convertVoucherDomainObjectToReadable))
      },
      (error: Error) => {
        if (error.name !== 'AbortError') {
          setFindVouchersStatus('failed')
          logError(new Error('Failed to fetch vouchers.'), error)
        }
      }
    )

    return () => {
      abort()
    }
  }, [serialNumber, fromDateInMs, toDateInMs])

  if (findVouchersStatus === 'loading') {
    return (
      <div className="centerAbsolute">
        <div className="loadingSpinner" />
      </div>
    )
  }

  if (findVouchersStatus === 'failed') {
    return (
      <div className="alert alert-danger mt-md max-w-screen-md mx-auto">
        Whoopsie! Failed to fetch vouchers. Please try again.
      </div>
    )
  }

  if (vouchers.length === 0) {
    return (
      <div className="alert alert-warning mt-md max-w-screen-md mx-auto">
        No vouchers found matching serial number {serialNumber} from
        {new Date(parseInt(fromDateInMs)).toLocaleDateString()} to {new Date(parseInt(toDateInMs)).toLocaleDateString()}
      </div>
    )
  }

  return (
    <VoucherList vouchers={vouchers}>
      <h2 className="font-bold">Serial {serialNumber}</h2>

      <CSVLink
        data={csvData}
        separator=";"
        headers={HEADERS}
        filename={'tomra_serialResults_' + new Date().toLocaleString('en-us', DATEOPTIONS) + '.csv'}
        className="btn mr-md"
      >
        Export
      </CSVLink>
    </VoucherList>
  )
}
