import React from 'react'
import styled from 'styled-components'

const Tooltip = styled.span<{ message: string }>`
  :hover::after {
    position: absolute;
    top: -40%;
    left: 0;
    content: '${props => props.message}';
    padding: var(--spacing-sm);
    background-color: var(--colors-midnight);
    color: white;
    border-radius: var(--radius-small);
    box-shadow: var(--shadow-small);
    z-index: 100;
  }
`

export const DateWithUTCTooltip = ({ date, timeZone }: { date: string; timeZone?: string }) => {
  const toolTipMessage = timeZone
    ? new Date(date).toLocaleString('default', { timeZone: 'UTC' }) + ' in UTC'
    : "Can't determine timezone of RVM. Therefore shown in UTC"

  return date ? (
    <Tooltip message={toolTipMessage} className="relative flex flex-col">
      {new Date(date).toLocaleString('default', { timeZone: timeZone || 'UTC' })}
      {!timeZone && <small className="text-red text-sm">Shown in UTC</small>}
    </Tooltip>
  ) : (
    <span>-</span>
  )
}
