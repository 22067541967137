import { fetchData } from '@tomra/client-side-http-client'
import { authentication } from './authentication'

const config = {
  fetchNewToken: authentication.fetchNewToken,
  getToken: authentication.getToken,
  retryCount: 0
}

export const authenticatedFetchData = (url: string, options?: RequestInit) => fetchData(url, options, config)
