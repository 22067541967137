import React, { useState, useContext, FormEvent } from 'react'
import { useHistory, Route } from 'react-router-dom'
import BarcodeResults from './BarcodeResults'
import { SearchHistoryContext } from '../../../contexts'
import { SearchHistory, SearchHistoryButton } from '../../common/SearchHistory'
import { loggedInUserHasRole } from '../../../lib'
import { ErrorIcon } from '../../icons'

export const BarcodeSearch = () => {
  const { updateSearchHistory } = useContext(SearchHistoryContext)
  const [value, setValue] = useState('')
  const [historyVisible, setHistoryVisible] = useState(false)
  const [formError, setFormError] = useState('')
  const history = useHistory()

  const _onSubmit = (evt: FormEvent) => {
    evt.preventDefault()

    if (!value) {
      setFormError('Please enter a barcode')
      return
    }

    setFormError('')

    const url = `/search/barcode/${value}`
    updateSearchHistory(url)
    history.push(url)
  }

  return (
    <React.Fragment>
      <h1 className="sr-only">Barcode Search</h1>

      <div className="max-w-screen-sm w-full mt-lg mx-auto">
        {loggedInUserHasRole('VOUCHER_CONTROL_ADMIN_MACHINE_SEARCH') && (
          <div className="tabs">
            <button onClick={() => history.push('/search/barcode')} className="tab active">
              Barcode
            </button>
            <button className="tab" onClick={() => history.push('/search/serial')} data-testid="serialNumberTab">
              Serial Number
            </button>
          </div>
        )}
        <form onSubmit={_onSubmit} className="relative mt-lg flex items-center space-x-md">
          <SearchHistoryButton active={historyVisible} onClick={() => setHistoryVisible(!historyVisible)} />

          <div className="w-2/3">
            <input
              aria-label="Search by barcode"
              type="text"
              value={value}
              placeholder="Search by barcode"
              onChange={evt => setValue(evt.target.value.trim())}
              className="p-md text-lg"
            />
            {formError && (
              <div className="flex items-center space-x-xs mt-sm">
                <ErrorIcon width="1.5rem" height="1.5rem" color="var(--colors-red)" />
                <div>{formError}</div>
              </div>
            )}
          </div>

          <button type="submit" className="btn btn-primary-dark w-1/3">
            Search
          </button>
        </form>

        {historyVisible && <SearchHistory close={() => setHistoryVisible(false)} />}
      </div>

      <Route path="/search/barcode/:barcode" component={BarcodeResults} />
    </React.Fragment>
  )
}
