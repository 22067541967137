export class HttpError extends Error {
  status: number
  statusText: string
  body: string | Object

  constructor(statusCode: number, statusText: string, body: string | Object) {
    super(`Unsuccessfull HTTP response: ${statusCode} ${statusText}`)
    this.name = 'HttpError'
    this.status = statusCode
    this.statusText = statusText
    this.body = body
  }
}
