import React, { useEffect } from 'react'
import { authentication } from '../lib'

export const AppMenuMountDiv = () => {
  useEffect(() => {
    // @ts-ignore
    window.tomraMenu.mount()
  }, [])

  return <div id="menu-root" data-token={authentication.getToken()} />
}
