import React, { useState } from 'react'
import { StatisticsList } from './StatisticsList'
import { API_HOST, authenticatedFetchData, logError } from '../../../lib'
import { CSVLink } from 'react-csv'

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

const HEADERS = [
  { label: 'Store name', key: 'storeName' },
  { label: 'Rvm serial', key: 'machineSerialNumber' },
  { label: 'New count', key: 'newCount' },
  { label: 'Reserved count', key: 'reservedCount' },
  { label: 'Consumed count', key: 'consumedCount' },
  { label: 'Digital count', key: 'digitalCount' }
]

const DATEOPTIONS: Intl.DateTimeFormatOptions = {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit'
}

type Props = {
  organizations: Array<{ [key: string]: string }>
  currentYear: number
  currentMonth: number
}

export const StatisticsSearch = ({ organizations, currentYear, currentMonth }: Props) => {
  const [statistics, setStatistics] = useState([])
  const [fetchStatisticsStatus, setFetchStatisticsStatus] = useState('')
  const [chosenOrganization, setChosenOrganization] = useState(organizations[0].id)
  const [chosenYear, setChosenYear] = useState(currentYear)
  const [chosenMonth, setChosenMonth] = useState(currentMonth)

  const renderYearOptions = () => {
    const options = []

    for (let i = 0; i <= 10; i++) {
      const year = currentYear - i

      options.push(
        <option value={year} key={year}>
          {year}
        </option>
      )
    }

    return options
  }

  const sendRequest = () => {
    setFetchStatisticsStatus('loading')
    setStatistics([])

    const url = `${API_HOST}/v1.0/statistics/organization/${chosenOrganization}?year=${chosenYear}&month=${
      chosenMonth + 1
    }`

    authenticatedFetchData(url)
      .run()
      .then(
        (statistics: []) => {
          setFetchStatisticsStatus('success')
          setStatistics(statistics)
        },
        (error: Error) => {
          setFetchStatisticsStatus('failed')
          logError(new Error(`Failed to fetch statistics for organization: ${chosenOrganization}.`), error)
        }
      )
  }

  return (
    <React.Fragment>
      <h1 className="sr-only">Organization statistics</h1>

      <div className="card my-lg">
        <div className="p-lg">
          <div className="grid grid-cols-12 gap-md">
            <div className="col-span-4">
              <label className="label" htmlFor="organization">
                Organization
              </label>
              <select id="organization" className="select" onChange={evt => setChosenOrganization(evt.target.value)}>
                {organizations.map(({ id, name }) => (
                  <option value={id} key={id}>
                    {name}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-span-2">
              <label className="label" htmlFor="year">
                Year
              </label>
              <select id="year" className="select" onChange={evt => setChosenYear(parseInt(evt.target.value))}>
                {renderYearOptions()}
              </select>
            </div>

            <div className="col-span-2">
              <label className="label" htmlFor="month">
                Month
              </label>
              <select id="month" className="select" onChange={evt => setChosenMonth(parseInt(evt.target.value))}>
                {MONTHS.map((month, index) => (
                  <option value={index} key={month} selected={chosenMonth === index}>
                    {month}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-span-4 flex items-center space-x-md self-end">
              <button className="btn btn-primary-dark" onClick={sendRequest}>
                Search
              </button>

              {statistics.length > 0 && (
                <CSVLink
                  data={statistics}
                  headers={HEADERS}
                  separator=";"
                  filename={'tomra_statistics_' + new Date().toLocaleString('en-us', DATEOPTIONS) + '.csv'}
                  className="btn"
                >
                  Export
                </CSVLink>
              )}
            </div>
          </div>
        </div>

        {fetchStatisticsStatus === 'loading' ? (
          <div className="centerAbsolute">
            <div className="loadingSpinner" />
          </div>
        ) : fetchStatisticsStatus === 'failed' ? (
          <div className="centerAbsolute alert alert-danger">Whoopsie! Failed fetch statistics. Please try again.</div>
        ) : statistics.length === 0 && fetchStatisticsStatus === 'success' ? (
          <div className="p-lg">No statistics found.</div>
        ) : statistics.length > 0 ? (
          <StatisticsList statistics={statistics} />
        ) : null}
      </div>
    </React.Fragment>
  )
}

StatisticsSearch.defaultProps = {
  currentYear: new Date().getFullYear(),
  currentMonth: new Date().getMonth()
}
