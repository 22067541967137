import React from 'react'

type RightArrowIconProps = {
  color?: string
  size?: string
}

export const RightArrowIcon = (props: RightArrowIconProps) => (
  <svg
    fill={props.color || 'var(--colors-black)'}
    height={props.size || '36'}
    viewBox="0 0 24 24"
    width={props.size || '36'}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z" />
    <path d="M0-.25h24v24H0z" fill="none" />
  </svg>
)

export default RightArrowIcon
