import React, { useState, useEffect, FormEvent, useContext } from 'react'
import styled from 'styled-components'
import { useHistory, useParams } from 'react-router-dom'
import RequestRow from './rows/RequestRow'
import StateRow from './rows/StateRow'
import { DateWithUTCTooltip } from '../../common'
import { logError, API_HOST, authenticatedFetchData, loggedInUserHasRole } from '../../../lib'
import { SearchHistoryContext } from '../../../contexts'
import { ReceiptDialog } from '../receipt/ReceiptDialog'
import { ErrorIcon } from '../../icons'

const VoucherDetail = styled.dl`
  margin: var(--spacing-sm) 0;

  > dt {
    display: block;
    color: var(--colors-storm);
  }
`

export type VoucherTimelineType = {
  eventType: 'CREATED' | 'STATE_UPDATE' | 'SOURCE_UPDATE' | 'REQUEST'
  timestamp: string
  state?: string
  source?: string
  createdBy?: string
  requestType?: string
  consumerId?: string
  internalResponse?: string
  responseCode?: number
  responseMessage?: string
  durationMillis?: number
  success?: boolean
  reservedUntil?: string
  rvmSerialNumber?: string
  clientId?: string
}

export interface VoucherType {
  barcode: string
  organizationId: string
  organizationName: string
  transactionTimestamp: string
  voucherState: 'NEW' | 'RESERVED' | 'CONSUMED' | 'DIGITAL'
  consumerId: string
  consumerLocation?: string
  refundAmount: string
  rvmSerialNumber: string
  csTimestamp: string
  csSource: string
  csDigital: boolean
  locationTimezone?: string
  locationName: string
}

export const VoucherTimeline = () => {
  const [timeline, setTimeline] = useState<VoucherTimelineType[]>([])
  const [voucher, setVoucher] = useState<VoucherType>()
  const [findTimelineStatus, setFindTimelineStatus] = useState('loading')
  const [showExportReceiptDialog, setShowExportReceiptDialog] = useState(false)
  const { barcode: barcodeParam, organizationId: organizationIdParam } = useParams<{ [key: string]: string }>()
  const { updateSearchHistory } = useContext(SearchHistoryContext)
  const [formError, setFormError] = useState('')
  const history = useHistory()

  useEffect(() => {
    const abortController = new AbortController()

    const url = `${API_HOST}/v1.0/search/timeline?barcode=${barcodeParam}&organization-id=${organizationIdParam}`

    authenticatedFetchData(url, { signal: abortController.signal })
      .run()
      .then(
        result => {
          setFindTimelineStatus('')
          setTimeline(result.timeline)
          setVoucher(result.voucher)
        },
        (error: Error) => {
          if (error.name !== 'AbortError') {
            setFindTimelineStatus('failed')
            logError(new Error('Failed to fetch voucher timeline.'), error)
          }
        }
      )

    return () => {
      abortController.abort()
    }
  }, [barcodeParam, organizationIdParam])

  const _onSubmit = (evt: FormEvent) => {
    evt.preventDefault()

    // @ts-ignore
    const value = evt.target.barcode.value.trim()
    setFormError('')

    if (!value) {
      setFormError('Please enter a barcode')
      return
    }

    const url = `/search/barcode/${value}`
    updateSearchHistory(url)
    history.push(url)
  }
  if (findTimelineStatus === 'loading') {
    return (
      <div className="centerAbsolute">
        <div className="loadingSpinner" />
      </div>
    )
  }

  if (findTimelineStatus === 'failed' || !voucher) {
    return (
      <h1 className="alert alert-danger centerAbsolute">Whoopsie! Failed fetch voucher timeline. Please try again.</h1>
    )
  }

  if (timeline.length === 0) {
    return (
      <h1 className="alert alert-warning centerAbsolute">
        No entries found matching barcode "{barcodeParam}" and organization ID "{organizationIdParam}"
      </h1>
    )
  }

  const {
    barcode,
    organizationName,
    transactionTimestamp,
    voucherState,
    consumerId,
    consumerLocation,
    refundAmount,
    rvmSerialNumber,
    csTimestamp,
    csSource,
    csDigital,
    locationTimezone,
    locationName
  } = voucher

  const canExportReceipt =
    loggedInUserHasRole('VOUCHER_CONTROL_ADMIN_EXPORT_RECEIPT') &&
    (voucherState === 'NEW' || voucherState === 'RESERVED')

  return (
    <div className="max-w-screen-2xl mx-auto p-lg">
      {canExportReceipt && showExportReceiptDialog && (
        <ReceiptDialog voucher={voucher} onClose={() => setShowExportReceiptDialog(false)} />
      )}

      <h1 className="text-xl mb-md">Voucher</h1>

      <form onSubmit={_onSubmit} className="flex items-center space-x-md">
        <input
          type="text"
          name="barcode"
          aria-label="Search by barcode"
          placeholder="Search by barcode"
          onChange={evt => evt.target.value.trim()}
          className="p-md text-lg w-3/4"
        />

        <button type="submit" className="btn btn-primary-dark w-1/4">
          Search
        </button>
      </form>
      {formError && (
        <div className="text-red mt-sm flex items-center space-x-sm">
          <ErrorIcon color="var(--colors-red)" width="1.5rem" height="1.5rem" />
          <div>{formError}</div>
        </div>
      )}

      <div className="flex flex-wrap items-start mt-lg">
        <div className="card flex flex-col p-lg mr-lg flex-auto space-y-md">
          <h2 className="text-xl mb-md">Details</h2>

          <VoucherDetail>
            <dt id="barcode">Barcode</dt>
            <dd aria-labelledby="barcode">{barcode}</dd>
          </VoucherDetail>
          <VoucherDetail>
            <dt id="organizationName">Organization</dt>
            <dd aria-labelledby="organizationName">{organizationName}</dd>
          </VoucherDetail>
          <VoucherDetail>
            <dt id="refund">Refund</dt>
            <dd aria-labelledby="refund">{parseFloat(refundAmount).toFixed(2)}</dd>
          </VoucherDetail>
          <VoucherDetail>
            <dt id="rvmSerial">RVM Serial</dt>
            <dd aria-labelledby="rvmSerial">{rvmSerialNumber}</dd>
          </VoucherDetail>
          <VoucherDetail>
            <dt id="rvmLocation">RVM Location</dt>
            <dd aria-labelledby="rvmLocation">{locationName}</dd>
          </VoucherDetail>
          <VoucherDetail>
            <dt id="state">State</dt>
            <dd aria-labelledby="state">{voucherState}</dd>
          </VoucherDetail>
          <VoucherDetail>
            <dt id="lastUpdated">Last Updated</dt>
            <dd aria-labelledby="lastUpdated">
              <DateWithUTCTooltip date={transactionTimestamp} timeZone={locationTimezone} />
            </dd>
          </VoucherDetail>
          <VoucherDetail>
            <dt id="consumerID">Consumer ID</dt>
            <dd aria-labelledby="consumerID">{consumerId}</dd>
          </VoucherDetail>
          <VoucherDetail>
            <dt id="consumerLocation">Consumer Location</dt>
            <dd aria-labelledby="consumerLocation">{consumerLocation}</dd>
          </VoucherDetail>
          <VoucherDetail>
            <dt id="source">Source</dt>
            <dd aria-labelledby="source">{csSource}</dd>
          </VoucherDetail>
          <VoucherDetail>
            <dt id="digital">Digital</dt>
            <dd aria-labelledby="digital">{String(csDigital)}</dd>
          </VoucherDetail>
          <VoucherDetail>
            <dt id="cs3StartTime">CS3 Start time</dt>
            <dd aria-labelledby="cs3StartTime">
              <DateWithUTCTooltip date={csTimestamp} timeZone={locationTimezone} />
            </dd>
          </VoucherDetail>

          {canExportReceipt && (
            <button className="btn" onClick={() => setShowExportReceiptDialog(true)}>
              Export receipt
            </button>
          )}
        </div>

        <div className="card p-xl relative flex flex-col flex-auto">
          <h2 className="text-xl mb-md">Timeline</h2>

          {timeline.map((entry, index) =>
            entry.eventType === 'REQUEST' ? (
              <RequestRow
                entry={entry}
                isFirstRow={index === 0}
                isLastRow={index === timeline.length - 1}
                locationTimezone={voucher.locationTimezone}
              />
            ) : (
              <StateRow
                entry={entry}
                isFirstRow={index === 0}
                isLastRow={index === timeline.length - 1}
                locationTimezone={voucher.locationTimezone}
              />
            )
          )}
        </div>
      </div>
    </div>
  )
}
