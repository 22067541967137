import React from 'react'

type Props = {
  label: string
  onChange: Function
}

type State = {
  filterValue: string
  loading: boolean
}

export class Filter extends React.Component<Props, State> {
  _timeoutRef: number = 0

  state: State = {
    filterValue: '',
    loading: false
  }

  componentWillUnmount() {
    window.clearTimeout(this._timeoutRef)
  }

  _onChange = (filterValue: string) => {
    this.setState({ filterValue, loading: true })

    window.clearTimeout(this._timeoutRef)

    this._timeoutRef = window.setTimeout(() => {
      this.setState({ loading: false })
      this.props.onChange(filterValue)
    }, 1000)
  }

  render() {
    const { filterValue, loading } = this.state
    const { label } = this.props

    return (
      <div className="flex items-center">
        {loading && <div className="loadingSpinner w-lg h-lg mr-sm" />}

        <input
          aria-label={label}
          type="text"
          value={filterValue}
          onChange={evt => this._onChange(evt.target.value.trim())}
          placeholder="Filter results"
        />
      </div>
    )
  }
}
