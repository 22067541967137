import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { authentication } from '../lib'
import { LogoutIcon } from './icons'
import tomraArrow from '../images/misc/tomraArrow.png'
import { loggedInUserHasRole } from '../lib'
import { AppMenuMountDiv } from './AppMenuMountDiv'

export const Header = () => {
  const hasStatisticsSearchRole = loggedInUserHasRole('VOUCHER_CONTROL_ADMIN_STATISTICS_SEARCH')
  const hasUserAdminRole = loggedInUserHasRole('VOUCHER_CONTROL_ADMIN_MANAGE_SOAP_CLIENTS')
  const showNavBar = hasStatisticsSearchRole || hasUserAdminRole

  return (
    <div className="navbar">
      <div className="w-full max-w-screen-2xl mx-auto flex items-center justify-between">
        <Link to="/search" className="flex items-center">
          <img src={tomraArrow} className="h-xl mr-md" alt="TOMRA logo" />
          <span className="text-white">Voucher Manager</span>
        </Link>

        {showNavBar && (
          <div className="flex flex-1 justify-center px-md">
            <NavLink className="navtab flex-1" to="/search">
              Search
            </NavLink>
            {hasStatisticsSearchRole && (
              <NavLink className="navtab flex-1" to="/statistics">
                Statistics
              </NavLink>
            )}
            {hasUserAdminRole && (
              <NavLink className="navtab flex-1" to="/users">
                Users
              </NavLink>
            )}
          </div>
        )}

        <div className="flex items-center space-x-md">
          <AppMenuMountDiv />

          <button className="btn btn-icon" aria-label="Log out" onClick={() => authentication.logout()}>
            <LogoutIcon width="2rem" height="2rem" color="white" />
          </button>
        </div>
      </div>
    </div>
  )
}
