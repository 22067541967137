import React from 'react'
import styled from 'styled-components'
import Circle from './Circle'
import { DateWithUTCTooltip } from '../../../common'
import { VoucherTimelineType } from '../VoucherTimeline'

const Container = styled.div`
  > span:first-child {
    min-width: 150px;
    margin-right: var(--spacing-lg);
  }
`

const RequestContainer = styled.div<{ message: string }>`
  :hover::after {
    position: absolute;
    top: -40%;
    left: 0;
    content: '${props => props.message}';
    padding: var(--spacing-sm);
    background-color: var(--colors-midnight);
    color: white;
    border-radius: var(--radius-small);
    box-shadow: var(--shadow-small);
    z-index: 100;
    max-width: 300px;
  }
`

type Props = {
  entry: VoucherTimelineType
  locationTimezone?: string
  isFirstRow: boolean
  isLastRow: boolean
}

export const RequestRow = ({ entry, locationTimezone, isFirstRow, isLastRow }: Props) => {
  const {
    requestType,
    consumerId,
    success,
    responseMessage,
    responseCode,
    timestamp,
    internalResponse,
    durationMillis,
    clientId
  } = entry

  const readableRequestMessageMap: { [key: string]: string } = {
    VOUCHER_RESERVED: 'Reserve',
    VOUCHER_CONSUMED: 'Consume',
    ENABLED_FOR_STORE_PAYOUT: 'Enable store payout',
    SET_TO_DIGITAL_PAYOUT: 'Disable store payout',
    DIGITAL_VOUCHER_CONSUMED_NOTIFIED: 'myTOMRA notification',
    UNKNOWN: 'Unknown'
  }

  const userIdMessage = `\n${clientId ? `ClientID: ${clientId}` : `ConsumerId: ${consumerId}`}`
  const readableRequestMessage = readableRequestMessageMap[requestType || 'UNKNOWN']
  const toolTipMessage = `Internal response: ${internalResponse} (${durationMillis} ms) \n${userIdMessage}`

  return (
    <Container className="flex items-center py-md">
      <DateWithUTCTooltip date={timestamp} timeZone={locationTimezone} />

      <Circle isFirstRow={isFirstRow} isLastRow={isLastRow} small />

      <RequestContainer
        className={`relative ml-lg p-md border rounded-half ${success ? 'border-green' : 'border-red'}`}
        message={toolTipMessage}
      >
        <div className="truncateText flex flex-col">
          <div className="flex items-center">
            <b className="mr-xs">{readableRequestMessage}</b>
            <span>request from consumer ID</span>
            <b className="ml-xs">{consumerId}</b>
          </div>

          <span className="mx-xs">Return code: {responseCode}</span>
          <span className="mx-xs">Return message: {responseMessage}</span>
        </div>
      </RequestContainer>
    </Container>
  )
}

export default RequestRow
