import { Dialog } from '../../common/Dialog'
import { RequestStatusType, SoapClientType } from './types'
import { useEffect, useState } from 'react'
import { API_HOST, authenticatedFetchData, logError } from '../../../lib'
import { ErrorIcon } from '../../icons'

export const UserDialog = (props: { user: SoapClientType; onClose: () => void }) => {
  const { onClose } = props
  const [user, setUser] = useState(props.user)
  const [fetchUserWithPasswordRequestStatus, setFetchUserWithPasswordRequestStatus] =
    useState<RequestStatusType>('idle')

  useEffect(() => {
    const handleKeypress = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose()
      }
    }

    window.addEventListener('keyup', handleKeypress)
    return () => window.removeEventListener('keyup', handleKeypress)
  }, [onClose])

  useEffect(() => {
    if (fetchUserWithPasswordRequestStatus === 'failure') {
      const timeoutId = window.setTimeout(() => setFetchUserWithPasswordRequestStatus('idle'), 3000)
      return () => window.clearTimeout(timeoutId)
    }
  }, [fetchUserWithPasswordRequestStatus])

  const fetchUserWithPassword = () => {
    setFetchUserWithPasswordRequestStatus('pending')

    const abortController = new AbortController()

    authenticatedFetchData(`${API_HOST}/v1.0/soap-clients/client/${user.userName}`, { signal: abortController.signal })
      .run()
      .then(
        (soapClient: SoapClientType) => {
          setUser(soapClient)
          setFetchUserWithPasswordRequestStatus('success')
        },
        (error: Error) => {
          if (error.name !== 'AbortError') {
            setFetchUserWithPasswordRequestStatus('failure')
            logError(new Error('Failed to fetch user with password.'), error)
          }
        }
      )

    return () => abortController.abort()
  }

  return (
    <Dialog>
      <div className="card flex flex-col space-y-md p-lg min-w-screen-sm max-w-screen-sm">
        <h1 className="text-lg mb-md">User details</h1>

        <dl className="grid grid-cols-12 gap-md">
          <dt id="username" className="label col-span-3">
            Username
          </dt>
          <dd aria-labelledby="username">{user.userName}</dd>
        </dl>

        <dl className="grid grid-cols-12 gap-md">
          <dt id="organization" className="label col-span-3">
            Organization
          </dt>
          <dd className="col-span-9" aria-labelledby="organization">
            {user.organizationName}
          </dd>
        </dl>

        <div className="grid grid-cols-12 gap-md items-center">
          <div id="password" className="label col-span-3">
            Password
          </div>
          <div className="col-span-9">
            {fetchUserWithPasswordRequestStatus === 'idle' ? (
              <button onClick={fetchUserWithPassword} className="btn btn-primary-dark">
                Show password
              </button>
            ) : fetchUserWithPasswordRequestStatus === 'pending' ? (
              <div className="loadingSpinner" />
            ) : fetchUserWithPasswordRequestStatus === 'success' && user.password ? (
              <span aria-labelledby="password">{user.password}</span>
            ) : (
              <div className="flex space-x-sm">
                <ErrorIcon color="var(--colors-red)" width="1.5rem" height="1.5rem" />
                <span className="font-bold text-red">Failed to fetch password</span>
              </div>
            )}
          </div>
        </div>

        <div className="flex justify-end">
          <button className="btn" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </Dialog>
  )
}
