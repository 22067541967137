import React from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import { VoucherList } from '../../common/VoucherList'
import { logError, API_HOST, authenticatedFetchData } from '../../../lib'
import { VoucherType } from '../voucherTimeline/VoucherTimeline'

type Props = {
  match: { params: { barcode: string } }
}

type State = {
  vouchers: VoucherType[]
  requestStatus: 'loading' | 'failed' | ''
}

class BarcodeResults extends React.Component<Props, State> {
  _mounted = false

  state: State = {
    vouchers: [],
    requestStatus: 'loading'
  }

  _fetchVouchers = async () => {
    const url = API_HOST + '/v1.0/search/barcode/' + this.props.match.params.barcode
    this.setState({ requestStatus: 'loading' })

    try {
      const vouchers = await authenticatedFetchData(url).run()
      if (this._mounted) this.setState({ requestStatus: '', vouchers })
    } catch (error: any) {
      if (this._mounted) this.setState({ requestStatus: 'failed' })
      logError(new Error('Failed to fetch vouchers.'), error)
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.match.params.barcode !== prevProps.match.params.barcode) {
      this._fetchVouchers()
    }
  }

  componentDidMount() {
    this._mounted = true
    this._fetchVouchers()
  }

  render() {
    const { vouchers, requestStatus } = this.state
    const { barcode } = this.props.match.params

    if (requestStatus === 'loading') {
      return (
        <div className="centerAbsolute">
          <div className="loadingSpinner" />
        </div>
      )
    }

    if (requestStatus === 'failed') {
      return <div className="alert alert-danger">Whoopsie! Failed to fetch vouchers. Please try again.</div>
    }

    if (vouchers.length === 0) {
      return <div className="alert alert-warning ">No vouchers found matching barcode {barcode}</div>
    }

    if (vouchers.length === 1 && !vouchers[0].voucherState.startsWith('ARCHIVED')) {
      return <Redirect to={`/transactions/${vouchers[0].barcode}/${vouchers[0].organizationId}`} />
    }

    return (
      <VoucherList vouchers={vouchers}>
        <h2>Barcode {barcode}</h2>
      </VoucherList>
    )
  }
}

// @ts-ignore
export default withRouter(BarcodeResults)
