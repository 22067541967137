import React, { useState, useContext, FormEvent } from 'react'
import { useHistory, Route } from 'react-router-dom'
import DatePicker from 'react-datepicker'
import { SerialResults } from './SerialResults'
import { SearchHistory, SearchHistoryButton } from '../../common/SearchHistory'
import { SearchHistoryContext } from '../../../contexts'
import { getQueryParameterByName, loggedInUserHasRole } from '../../../lib'
import { ErrorIcon } from '../../icons'

const oneWeekInMs = 604800000

type Props = {
  today: Date
}

export const SerialSearch = ({ today }: Props) => {
  const { updateSearchHistory } = useContext(SearchHistoryContext)
  const [historyVisible, setHistoryVisible] = useState(false)
  const fromDateInMs = getQueryParameterByName('fromDate')
  const toDateInMs = getQueryParameterByName('toDate')
  const oneWeekAgo = new Date(today.getTime() - oneWeekInMs)

  const [value, setValue] = useState('')
  const [fromDate, setFromDate] = useState(fromDateInMs ? new Date(parseInt(fromDateInMs)) : oneWeekAgo)
  const [toDate, setToDate] = useState(toDateInMs ? new Date(parseInt(toDateInMs)) : today)
  const [formError, setFormError] = useState('')

  const history = useHistory()

  const _onSubmit = (evt: FormEvent) => {
    evt.preventDefault()

    if (!value) {
      setFormError('Please enter a serial number')
      return
    }

    const url = `/search/serial/${value}?fromDate=${fromDate.getTime()}&toDate=${toDate.getTime()}`
    updateSearchHistory(url)
    history.push(url)
  }

  return (
    <React.Fragment>
      <h1 className="sr-only">Search by RVM serial number</h1>

      <div className="max-w-screen-sm w-full mt-lg mx-auto">
        {loggedInUserHasRole('VOUCHER_CONTROL_ADMIN_MACHINE_SEARCH') && (
          <div className="tabs">
            <button className="tab" onClick={() => history.push('/search/barcode')}>
              Barcode
            </button>
            <button onClick={() => history.push('/search/serial')} data-testid="serialNumberTab" className="tab active">
              Serial Number
            </button>
          </div>
        )}

        <div className="flex items-center justify-between mt-lg relative">
          <div>
            <label htmlFor="fromDate" className="label">
              From
            </label>
            <DatePicker
              id="fromDate"
              dateFormat="dd/MM/yyyy"
              selected={fromDate}
              onChange={(date: Date) => setFromDate(new Date(date.setHours(0, 0, 0, 0)))}
            />
          </div>

          <div className="absolute mt-[1.6rem] translate-y-1/2 w-full h-[1px] bg-grey -z-[1]" />

          <div>
            <label htmlFor="toDate" className="label">
              To
            </label>
            <DatePicker
              id="toDate"
              dateFormat="dd/MM/yyyy"
              selected={toDate}
              onChange={(date: Date) => setToDate(new Date(date.setHours(23, 59, 59, 999)))}
              minDate={fromDate}
            />
          </div>
        </div>

        <form onSubmit={_onSubmit} className="relative pt-lg flex items-center space-x-md">
          <SearchHistoryButton active={historyVisible} onClick={() => setHistoryVisible(!historyVisible)} />

          <div className="w-2/3">
            <input
              type="text"
              value={value}
              placeholder="Search by serial"
              onChange={evt => setValue(evt.target.value.trim())}
              aria-label="Search by RVM serial number"
              className="p-md text-lg"
            />
            {formError && (
              <div className="flex items-center space-x-xs mt-sm">
                <ErrorIcon width="1.5rem" height="1.5rem" color="var(--colors-red)" />
                <div>{formError}</div>
              </div>
            )}
          </div>

          <button type="submit" className="btn btn-primary-dark w-1/3">
            Search
          </button>
        </form>

        {historyVisible && <SearchHistory close={() => setHistoryVisible(false)} />}
      </div>

      <Route path="/search/serial/:serialNumber" component={SerialResults} />
    </React.Fragment>
  )
}

SerialSearch.defaultProps = {
  today: new Date()
}
