import { Dialog } from '../../common/Dialog'
import { ChangeEvent, useEffect, useState } from 'react'
import { OrganizationType, RequestStatusType, SoapClientType } from './types'
import { API_HOST, authenticatedFetchData, logError } from '../../../lib'
import React from 'react'

export const AddNewUserDialog = ({
  organizations,
  addSoapClient,
  onClose
}: {
  organizations: OrganizationType[]
  addSoapClient: (soapClient: SoapClientType) => void
  onClose: () => void
}) => {
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<string>()
  const [inputOrganizationId, setInputOrganizationId] = useState<string>()
  const [userName, setUserName] = useState<string>()
  const isDataInvalid = selectedOrganizationId === undefined || userName === undefined
  const [submitRequestStatus, setSubmitRequestStatus] = useState<RequestStatusType>('idle')

  useEffect(() => {
    const handleKeypress = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose()
      }
    }

    window.addEventListener('keyup', handleKeypress)
    return () => window.removeEventListener('keyup', handleKeypress)
  }, [onClose])

  useEffect(() => {
    if (submitRequestStatus === 'failure') {
      const timeoutId = window.setTimeout(() => setSubmitRequestStatus('idle'), 3000)
      return () => window.clearTimeout(timeoutId)
    }
  }, [submitRequestStatus, onClose])

  useEffect(() => {
    if (inputOrganizationId && organizations.map(organization => organization.id).includes(inputOrganizationId)) {
      setSelectedOrganizationId(inputOrganizationId)
    }
  }, [inputOrganizationId, organizations])

  const onSelectOrganization = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedOrganizationId(e.currentTarget.value)
  }

  const onChangeOrganizationId = (e: ChangeEvent<HTMLInputElement>) => {
    setInputOrganizationId(e.currentTarget.value)
  }

  const onChangeUsername = (e: ChangeEvent<HTMLInputElement>) => {
    setUserName(e.currentTarget.value)
  }

  const submitUser = () => {
    setSubmitRequestStatus('pending')

    authenticatedFetchData(`${API_HOST}/v1.0/soap-clients`, {
      method: 'POST',
      body: JSON.stringify({
        userName: userName,
        organizationId: selectedOrganizationId
      })
    })
      .run()
      .then(
        (newSoapClient: SoapClientType) => {
          addSoapClient(newSoapClient)
          setSubmitRequestStatus('success')
          onClose()
        },
        (error: Error) => {
          setSubmitRequestStatus('failure')
          logError(new Error('Failed to submit new user.'), error)
        }
      )
  }

  return (
    <Dialog>
      {submitRequestStatus === 'failure' ? (
        <div className="alert alert-danger">Failed to create new soap client</div>
      ) : (
        <React.Fragment>
          <div className="card p-lg flex flex-col space-y-md min-w-screen-sm max-w-screen-sm">
            <h1 className="text-xl">Add new user</h1>

            <div>
              <label htmlFor="organization" className="label">
                Organization
              </label>
              <select
                id="organization"
                className="select"
                value={selectedOrganizationId}
                defaultValue="default"
                onChange={onSelectOrganization}
              >
                <option disabled hidden value="default" />
                {organizations
                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map(organization => (
                    <option key={organization.id} value={organization.id}>
                      {organization.name}
                    </option>
                  ))}
              </select>
            </div>

            <div>
              <label htmlFor="organization-id" className="label">
                Organization id
              </label>
              <input
                id="organization-id"
                type="text"
                defaultValue={selectedOrganizationId}
                onChange={onChangeOrganizationId}
              />
            </div>

            <div>
              <label htmlFor="username" className="label">
                Username
              </label>
              <input id="username" type="text" onChange={onChangeUsername} placeholder="Username" />
            </div>

            <div className="flex space-x-md justify-end">
              <button className="btn" onClick={onClose}>
                Close
              </button>

              {submitRequestStatus === 'pending' ? (
                <div className="loadingSpinner" />
              ) : (
                <button className="btn btn-primary-dark" onClick={submitUser} disabled={isDataInvalid}>
                  Submit
                </button>
              )}
            </div>
          </div>
        </React.Fragment>
      )}
    </Dialog>
  )
}
