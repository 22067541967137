import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { SearchHistoryContext } from '../../contexts'
import { getQueryParameterByName } from '../../lib'
import { TimeIcon } from '../icons'

type SearchHistoryButtonProps = {
  onClick: () => void
  active: boolean
}

export const SearchHistoryButton = ({ onClick, active }: SearchHistoryButtonProps) => {
  const { searchHistory } = useContext(SearchHistoryContext)

  return searchHistory.length > 0 ? (
    <button type="button" onClick={onClick} className="btn btn-icon" aria-label="Show search history">
      <TimeIcon width="2rem" height="2rem" color={active ? 'var(--colors-blue)' : 'var(--colors-black)'} />
    </button>
  ) : null
}

export const SearchHistory = ({ close }: { close: Function }) => {
  const { searchHistory } = useContext(SearchHistoryContext)

  return (
    <div className="mt-sm whitespace-nowrap" onClick={() => close()}>
      <div className="flex items-center mt-lg mb-sm">
        <h2 className="mr-sm">History</h2>
        <small>(last 5 searches)</small>
      </div>

      <div className="flex flex-col-reverse">
        {searchHistory.map((url: string) => {
          const fromDateInMs = getQueryParameterByName('fromDate', url)
          const toDateInMs = getQueryParameterByName('toDate', url)
          const serial = url.slice(url.indexOf('serial'), url.indexOf('?'))
          const barcode = url.slice(url.indexOf('barcode'))

          return serial && fromDateInMs && toDateInMs ? (
            <Link to={url} key={url} className="my-xs underline">
              {`Serial ${serial.slice(serial.indexOf('/') + 1)} between ${new Date(
                parseInt(fromDateInMs)
              ).toLocaleDateString()} and ${new Date(parseInt(toDateInMs)).toLocaleDateString()}`}
            </Link>
          ) : (
            <Link to={url} key={url} className="my-xs underline">
              {`Barcode ${barcode.slice(barcode.indexOf('/') + 1)}`}
            </Link>
          )
        })}
      </div>
    </div>
  )
}
